<template>
  <div>
    <template>
      <div class="d-flex">
       
        <div class="col-sm-3 pt-4">
          <v-text-field
            dense
            :disabled="loading"
            outlined
            hide-details
            label="by Policy Number"
            prepend-inner-icon="mdi-magnify"
            v-model="policyNumber"
            clearable
          ></v-text-field>
        </div>
        <div class="col-sm-4">
          <v-switch           
            label="No Imported Month"
            :loading="loading"
            :disabled="loading"
            inset
            @change="getPayments"
            v-model="notImportedMonth"
          ></v-switch>
          
        </div>
        <v-spacer></v-spacer>
        <v-btn small :disabled="loading" color="primary" text @click="openFilters"
          ><v-icon>mdi-filter-variant</v-icon> Filters</v-btn
        >
        <v-btn text small :disabled="loading" color="primary" @click="getPayments">
          <v-icon>mdi-refresh</v-icon> Update</v-btn
        >
      </div>

      <template>
        <v-data-table
          dense
          :loading="loading"
          :headers="headers"
          :items="payments"
          :options.sync="options"
          :server-items-length="total"
          :footer-props="footerProps"
        >
          <template v-slot:[`header.payment`]="{ header }">
            <div class="fill-width background-green pa-1">
              <div class="text--center">{{ header.text }}</div>
              <v-row no-gutters class="fill-width">
                <v-col cols="12" sm="4"> Monthly Fee </v-col>
                <v-col cols="12" sm="4"> Over rate </v-col>
                <v-col cols="12" sm="4"> Bond </v-col>
              </v-row>
            </div>
          </template>
          <template v-slot:[`header.chargeBack`]="{ header }">
            <div class="fill-width background-red pa-1">
              <div class="text--center">{{ header.text }}</div>
              <v-row no-gutters class="fill-width">
                <v-col cols="12" sm="4"> Monthly Fee </v-col>
                <v-col cols="12" sm="4"> Over rate </v-col>
                <v-col cols="12" sm="4"> Bond </v-col>
              </v-row>
            </div>
          </template>
          <template v-slot:[`header.balance`]="{ header }">
            <div class="fill-width background-orange pa-1">
              <div class="text--center">{{ header.text }}</div>
              <v-row no-gutters class="fill-width">
                <v-col cols="12" sm="3"> Monthly Fee </v-col>
                <v-col cols="12" sm="2"> Over rate </v-col>
                <v-col cols="12" sm="2"> Bond </v-col>
                <v-col cols="12" sm="2"> Amount </v-col>
                <v-col cols="12" sm="3"> Range </v-col>
              </v-row>
            </div>
          </template>
          <template v-slot:[`header.totalmembers`]="{ header }">
          {{ `${header.text}(${totalMembers})` }}
        </template>
          <template v-slot:[`item.polizestatus`]="{ item }">
            <div
              class="text-body-1 cursor-pointer"
              v-bind:style="{ color: colorShow(item) }"
            >
              {{ statusShow(item) }}
            </div>
          </template>
          <template v-slot:[`item.fullname`]="{ item }">
            <div class="text-body-1 cursor-pointer" @click="showDetails(item)">
              {{ item.fullname }}
            </div>
          </template>
          <template v-slot:[`item.paymentstatus`]="{ item }">
            <template v-if="item.paymentstatus">
              <span
                v-bind:style="{ color: colorShowPaymentStatus(item.paymentstatus) }"
                class="font-weight-bold"
                >{{ ToshowPaymentStatus(item.paymentstatus) }}</span
              >
            </template>
          </template>
          <template v-slot:[`item.effectivedate`]="{ item }">
            <span>{{
              item.effectivedate ? showEffective(item.effectivedate) : "-"
            }}</span>
          </template>
          <template v-slot:[`item.cancelleddate`]="{ item }">
            <span>{{ item.cancelleddate ? formatDate(item.cancelleddate) : "-" }}</span>
          </template>

          <template v-slot:[`item.payment`]="{ item }">
            <template v-if="item">
              <v-row no-gutters class="fill-width fill-height background-green">
                <v-col cols="12" sm="4" class="d-flex justify-center align-center">
                  {{ item.pay_monthly_fee | currency }}
                </v-col>
                <v-col cols="12" sm="4" class="d-flex justify-center align-center">
                  {{ item.pay_overate | currency }}
                </v-col>
                <v-col cols="12" sm="4" class="d-flex justify-center align-center">
                  {{ item.pay_bonus | currency }}
                </v-col>
              </v-row>
            </template>
          </template>
          <template v-slot:[`item.chargeBack`]="{ item }">
            <v-row no-gutters class="fill-width fill-height background-red">
              <v-col cols="12" sm="4" class="d-flex justify-center align-center">
                {{ item.chargeback_monthly_fee | currency }}
              </v-col>
              <v-col cols="12" sm="4" class="d-flex justify-center align-center">
                {{ item.chargeback_pay_overate | currency }}
              </v-col>
              <v-col cols="12" sm="4" class="d-flex justify-center align-center">
                {{ item.chargeback_pay_bonus | currency }}
              </v-col>
            </v-row>
          </template>
          <template v-slot:[`item.balance`]="{ item }">
            <v-row no-gutters class="fill-width fill-height background-orange">
              <v-col cols="12" sm="3" class="d-flex justify-center align-center">
                {{ item.balance_monthly_fee | currency }}
              </v-col>
              <v-col cols="12" sm="2" class="d-flex justify-center align-center">
                {{ item.balance_overate | currency }}
              </v-col>
              <v-col cols="12" sm="2" class="d-flex justify-center align-center">
                {{ item.balance_bonus | currency }}
              </v-col>
              <v-col cols="12" sm="2" class="d-flex justify-center align-center">
                {{ item.balance_amount | currency }}
              </v-col>
              <v-col cols="12" sm="3" class="d-flex justify-center align-center">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-icon small v-on="on" color="white">{{
                      getRange(item).icon
                    }}</v-icon>
                  </template>
                  <span>{{ getRange(item).tooltip }}</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </template>
        </v-data-table>
      </template>
    </template>
  </div>
</template>
<script>
import { getAPI } from "@/api/axios-base";
import { notifyError } from "@/components/Notification";
import { PolicyStatus } from "@/utils/enums/policystatus";
import moment from "moment";
import { mapMutations } from "vuex";
export default {
  props: {
    byagent: String,
    ptoNoms: Array,
    effectiveDat:Array,
    pstatus: String,
    comId:String,
    yandM: Array,
    flexp: Boolean,
    yearByAgent: Number,
    monthByAgent: String,
  },
  data() {
    return {
      filterExpand: false,
      clear: false,
      loading: false,
      total: 0,
      notImportedMonth:false,
      totalMembers:0,
      options: {},
      yearAndMonths:null,
     /*  yearsAndMonths: 
        {
          year: Number(moment().format("YYYY")),
          months: [
            "JANUARY",
            "FEBRUARY",
            "MARCH",
            "APRIL",
            "MAY",
            "JUNE",
            "JULY",
            "AUGUST",
            "SEPTEMBER",
            "OCTOBER",
            "NOVEMBER",
            "DECEMBER",
          ],
        }, */
      
      employeeUuid: null,
      policyNumber: null,
      companyId: null,
      effectiveDate:[],
      yearAndMonthByAgent: {
        year: null,
        month: null,
      },
      payToNomIds: [],
      paymentStatus: null,
      paymentStats: [
        { name: "Pay", value: "PAY" },
        { name: "Reconciliation", value: "RECONCILIATION" },
        { name: "Member Problem", value: "MEMBER_PROBLEM" },
        { name: "Charge Back", value: "CHARGE_BACK" },
        { name: "End", value: "END" },
        { name: "Review", value: "REVIEW" },
      ],
      payments: [],

      headers: [
        { text: "Name", width: 300, value: "fullname", sortable: false },
        { text: "#Policy", width: 200, value: "polizenumber", sortable: false },
        { text: "Company", width: 200, value: "company", sortable: false },
        { text: "Status", width: 200, value: "polizestatus", sortable: false },
        { text: "Payment Status", width: 200, value: "paymentstatus", sortable: false },
        { text: "Effective Date", width: 200, value: "effectivedate", sortable: false },
        {
          text: "Cancelation Date",
          width: 200,
          value: "cancelleddate",
          sortable: false,
        },
        { text: "Members", width: 200, value: "totalmembers", sortable: false },
        { text: "#Months Charged", width: 200, value: "charge_months", sortable: false },
        { text: "Pay To", width: 200, value: "paytoname", sortable: false },
        {
          text: "Payment",
          width: 300,
          align: "center",
          value: "payment",
          sortable: false,
        },
        {
          text: "Charge Back",
          width: 300,
          align: "center",
          value: "chargeBack",
          sortable: false,
        },
        {
          text: "Balance",
          width: 450,
          align: "center",
          value: "balance",
          sortable: false,
        },
      ],

      footerProps: {
        "items-per-page-options": [25, 50, 100, 250, 500, 1000],
      },
    };
  },

  watch: {
    options(val) {
      if (val != {}) {
        this.getPayments();
      }
    },

    policyNumber(val) {
      this.policyNumber = val != null ? val.toLocaleUpperCase() : val;
      if (val == null) {
        this.getPayments();
      } else if (val.length >= 5 || val.length == 0) {
        this.getPayments();
      }
    },
    ptoNoms: {
      handler(val) {
        this.payToNomIds = val;
        this.getPayments();
      },
    },
    effectiveDat: {
      handler(val) {
        console.log(val);
        this.effectiveDate = val;
        this.getPayments();
      },
    },
    byagent: {
      handler(val) {
        this.employeeUuid = val;
        this.getPayments();
      },
    },
    comId: {
      handler(val) {
        this.companyId = val;
        this.getPayments();
      },
    },
    pstatus: {
      handler(val) {
        this.paymentStatus = val;
        this.getPayments();
      },
    },
    flexp: {
      handler(val) {
        if (val == false) {
          this.filterExpand = false;
        }
      },
    },

    yearByAgent: {
      handler(val) {
        this.yearAndMonthByAgent = {
          year: val,
          month: this.monthByAgent,
        };
        if (val == null && this.monthByAgent == null) {
          if (this.clear == false) {
            this.clear = true;
            this.getPayments();
          }
        } else if (val && this.monthByAgent) {
          this.getPayments();
        }
      },
    },
    monthByAgent: {
      handler(val) {
        this.yearAndMonthByAgent = {
          year: this.yearByAgent,
          month: val,
        };
        if (val == null && this.yearByAgent == null) {
          if (this.clear == false) {
            this.clear = true;
            this.getPayments();
          }
        } else if (val && this.yearByAgent) {
          this.getPayments();
        }
      },
    },

     /*  Var Months and Years */
     yandM: {
      handler(val, val1) {
        if (val != val1) {
          if (val.length == 0) {
            this.yearAndMonths=null;
           /*  this.yearSelect = Number(moment().format("YYYY"));
            this.yearsAndMonths = {
              year: Number(moment().format("YYYY")),
              months: [
                "JANUARY",
                "FEBRUARY",
                "MARCH",
                "APRIL",
                "MAY",
                "JUNE",
                "JULY",
                "AUGUST",
                "SEPTEMBER",
                "OCTOBER",
                "NOVEMBER",
                "DECEMBER",
              ],
            }; */
          } else {
            const y = Number(moment(val[0]).format("YYYY"));
            this.yearSelect = y;
            const m = val.map((v) => {
              return moment(v).format("MMMM").toLocaleUpperCase().toString();
            });
            this.yearAndMonths = {
              year: y,
              months: m,
            };
          }
          this.getPayments();
        }
      },
    },
  },
  methods: {
    getPayments() {
      this.loading = true;

      const { page, itemsPerPage } = this.options;
      const itmper = itemsPerPage != -1 ? itemsPerPage : 50;
      let body = {
        notImportedMonth:this.notImportedMonth,
        limit: itmper,
        offset: (page - 1) * itmper,
      };
      if (this.paymentStatus) {
        body = { ...body, paymentStatus: this.paymentStatus };
      }
      if (this.effectiveDate.length!=0) {
        body = { ...body, effectiveDate: this.effectiveDate };
      }
      if (this.employeeUuid) {
        body = { ...body, employeeUuid: this.employeeUuid };
      }
      if (this.companyId) {
        body = { ...body, companyId: this.companyId };
      }
      if (this.policyNumber) {
        body = { ...body, policyNumber: this.policyNumber };
      }
      if (this.payToNomIds.length != 0) {
        body = { ...body, payToNomIds: this.payToNomIds };
      }
      if (
        this.yearAndMonthByAgent.year != null &&
        this.yearAndMonthByAgent.month != null
      ) {
        body = { ...body, yearAndMonthByAgent: this.yearAndMonthByAgent };
      }

      if (this.yearAndMonths) {
        body = { ...body, yearAndMonths: this.yearAndMonths };
      }
     
      getAPI
        .post("payment/statics1", body)
        .then((res) => {
          this.totalMembers=res.data.totalMembers;
          this.total = res.data.count;
          this.payments = res.data.result;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
        });
    },

    statusShow(item) {
      const status = item.polizestatus;
      if (status) {
        const st = PolicyStatus[status];
        return st;
      }

      return "-";
    },

    colorShow(item) {
      const status = item.polizestatus;
      switch (status) {
        case "ACTIVE":
          return "#19FF00FF";
        case "ACTIVE_PENDING":
          return "#4B7ECE";
        case "CANCELLED":
          return "#FF4400";
        case "PAY_DUE":
          return "#4B7ECE";
        case "REVIEW":
          return "#EEC167";
        case "BLACKLIST":
          return "#270E41";
      }
    },
    colorShowPaymentStatus(item) {
      switch (item) {
        case "PAY":
          return "#19FF00FF";
        case "RECONCILIATION":
          return "#4B7ECE";
        case "MEMBER_PROBLEM":
          return "#ff571a";
        case "CHARGE_BACK":
          return "#FF4400";
        case "END":
          return "#19FF00FF";
        case "REVIEW":
          return "#EEC167";

        default:
          return "#000";
      }
    },
    ToshowPaymentStatus(item) {
      switch (item) {
        case "PAY":
          return "Pay";
        case "RECONCILIATION":
          return "Reconciliation";
        case "MEMBER_PROBLEM":
          return "Member Problem";
        case "CHARGE_BACK":
          return "Charge Back";
        case "END":
          return "End";
        case "REVIEW":
          return "Review";
      }
    },

    showEffective(effective) {
      return moment(effective).startOf("month").format("MMMM, YYYY");
    },
    formatDate(date) {
      return moment(date).format("MMMM, YYYY");
    },

    getRange(item) {
      let x = Number(item.balance_amount);

      if (x > 0) {
        return {
          icon: "mdi-arrow-up-bold-circle-outline",
          color: "#66BB6A",
          tooltip: "Positive",
        };
      }
      if (x < 0) {
        return {
          icon: "mdi-chevron-double-down",
          color: "#EF5350",
          tooltip: "Negative",
        };
      }
      return { icon: "mdi-null", color: "#000", tooltip: "Cero" };
    },

    showDetails(value) {
      this.$router.push(`/healths/details/${value.pol_uuid}`);
    },

    openFilters() {
      this.filterExpand = !this.filterExpand;
      this.$emit("openFilter", this.filterExpand);
    },
  },
  async mounted() {
    //
  },
};
</script>
<style lang="scss" scoped></style>
