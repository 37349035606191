var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex pt-4"},[_c('div',{staticClass:"col-sm-2"},[_c('v-select',{attrs:{"items":_vm.typepays,"dense":"","clearable":"","item-text":"label","item-value":"value","label":"Pays","outlined":""},model:{value:(_vm.onlyPositive),callback:function ($$v) {_vm.onlyPositive=$$v},expression:"onlyPositive"}})],1),_c('div',{staticClass:"col-sm-3"},[[_c('v-text-field',{attrs:{"label":'Search',"hide-details":"","prepend-inner-icon":"mdi-magnify","clearable":"","outlined":"","dense":""},model:{value:(_vm.query),callback:function ($$v) {_vm.query=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"query"}})]],2),_c('v-spacer'),_c('div',{staticClass:"col-sm-3 d-flex justify-end"},[_c('v-btn',{attrs:{"text":"","small":"","color":"primary"},on:{"click":_vm.getImportLogs}},[_c('v-icon',[_vm._v("mdi-refresh")]),_vm._v(" Update")],1)],1)],1),_c('v-divider'),_c('v-data-table',{attrs:{"dense":"","loading":_vm.loading,"headers":_vm.headers,"items":_vm.logs,"options":_vm.options,"server-items-length":_vm.total,"footer-props":_vm.footerProps},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"cursor-pointer"},[_vm._v(" "+_vm._s(_vm.formatDate(item.createdAt))+" ")])]}},{key:"header.clientName",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(((header.text) + " (" + (_vm.summary?_vm.summary.total_person:'') + ")"))+" ")]}},{key:"item.clientName",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"cursor-pointer",class:item.patientUuid ? '' : 'red--text',on:{"click":function($event){return _vm.showDetails(item)}}},[_vm._v(" "+_vm._s(item.clientName)+" ")])]}},{key:"item.selectedDate",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"cursor-pointer"},[_vm._v(" "+_vm._s(item.monthByAgent + ", " + item.yearByAgent)+" ")])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }